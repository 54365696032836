export default {
    props: ['content', 'css'],
    data:
        function () {
                    return {
                                CONSTANTS:
                                {
                                },
                    }
                },
     created:
        function () {
		    this.eventHub.$emit('hideNavbar');
        }

}
